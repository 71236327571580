import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tools } from '@babylonjs/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (theme.palette.background as any).dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const SimpleLayout = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  // if using the simple layout, the babylon code needs to turn off the custom Bearer token
  Tools.CustomRequestHeaders.Authorization = '';
  Tools.UseCustomRequestHeaders = false;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SimpleLayout;
