import { Box, CircularProgress, Typography } from '@material-ui/core';

export default function LoadingScreen() {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'absolute'}
      zIndex={9999}
      width={'100vw'}
      height={'100vh'}
      style={{ backgroundColor: 'rgba(255,255,255,0.75)' }}
    >
      <Box>
        <CircularProgress variant={'indeterminate'} size={200} thickness={1} />
      </Box>
      <Box mt={2}>
        <Typography variant={'h3'}>Loading...</Typography>
      </Box>
    </Box>
  );
}
