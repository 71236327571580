import { createContext, useContext, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { ProgressButton } from '../components/ProgressButton';

const ConfirmationServiceContext = createContext(Promise.reject);

export const useConfirm = () => useContext(ConfirmationServiceContext);

export const ConfirmServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null);

  const awaitingPromiseRef = useRef();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <Dialog open={Boolean(confirmationState)}>
        <DialogTitle disableTypography={true}>
          <Typography variant={'h4'}>{confirmationState?.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography component={'div'}>
            {confirmationState?.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          {confirmationState?.disableCancel ? null : (
            <ProgressButton
              autoFocus
              variant={'text'}
              color={'primary'}
              onClick={handleClose}
              label={'Cancel'}
            />
          )}
          <ProgressButton
            color={'primary'}
            onClick={handleSubmit}
            label={'Ok'}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
