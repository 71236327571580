import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import { AuthProvider } from './contexts/OAuthContext';
import { createCustomTheme } from './theme';
import routes, { renderRoutes } from './routes';
import DateFnsUtils from '@date-io/date-fns';
import { ConfirmServiceProvider } from './hooks/useConfirm';

const jss = create({ plugins: [...jssPreset().plugins] });
const history = createBrowserHistory();

function App() {
  const theme = createCustomTheme({ theme: 'LIGHT' });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <ConfirmServiceProvider>
              <Router history={history}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  {renderRoutes(routes as any)}
                </AuthProvider>
              </Router>
            </ConfirmServiceProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
