import axios from 'axios';
import config from '../extras/config';

const axiosConfig = {
  baseURL: config.apiHost,
};
const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('user');

      window.location.href = '/login?reason=CLIENT_ERROR';
    }

    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    );
  },
);

export default axiosInstance;
