const {
  REACT_APP_WORKFLOW_URL,
  REACT_APP_API_HOST,
  REACT_APP_BRANCH,
  REACT_APP_BUILD_NUMBER,
  REACT_APP_GTIN,
  REACT_APP_VERSION,
  REACT_APP_DICOM_MAX_FILE_SIZE_BYTES,
  REACT_APP_SHOULD_DISPLAY_PREOP_DIFFERENCES,
  REACT_APP_AUTH_METHOD,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTH_SCOPE,
  REACT_APP_AUTH_RESPONSE_TYPE,
  REACT_APP_OAUTH_URL,
  REACT_APP_OAUTH_IDP_JUMPCLOUD,
  REACT_APP_OAUTH_IDP_GOOGLE,
  REACT_APP_FF_ASSET_POSITIONS_V2,
} = process.env;

const config = {
  auth: {
    method: REACT_APP_AUTH_METHOD,
    clientId: REACT_APP_AUTH_CLIENT_ID,
    redirectUri: REACT_APP_AUTH_REDIRECT_URI,
    scope: REACT_APP_AUTH_SCOPE,
    responseType: REACT_APP_AUTH_RESPONSE_TYPE,
    oauth: {
      url: REACT_APP_OAUTH_URL,
      idp: {
        jumpCloud: REACT_APP_OAUTH_IDP_JUMPCLOUD,
        google: REACT_APP_OAUTH_IDP_GOOGLE,
      },
    },
  },
  apiHost: REACT_APP_API_HOST,
  workflowUrl: REACT_APP_WORKFLOW_URL,
  version: REACT_APP_VERSION,
  branch: REACT_APP_BRANCH,
  buildNumber: REACT_APP_BUILD_NUMBER,
  gtin: REACT_APP_GTIN,
  dicomMaxFileSizeBytes: REACT_APP_DICOM_MAX_FILE_SIZE_BYTES,
  shouldDisplayPreopDifferences:
    REACT_APP_SHOULD_DISPLAY_PREOP_DIFFERENCES === 'true',
  featureFlags: {
    assetPositionsV2: REACT_APP_FF_ASSET_POSITIONS_V2 === 'true',
  },
};

export default config;
