import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import SimpleLayout from './layouts/SimpleLayout';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = (route as any).guard || Fragment;
        const Layout = (route as any).layout || Fragment;
        const Component = (route as any).component;

        return (
          <Route
            key={i}
            path={(route as any).path}
            exact={(route as any).exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {(route as any).routes ? (
                    renderRoutes((route as any).routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/code',
    component: lazy(() => import('./views/auth/CodeView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: SimpleLayout,
    routes: [
      {
        exact: true,
        path: '/app/integrated/:caseNumber?',
        component: lazy(() => import('./views/integrated')),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/integrated/" />,
      },
      {
        component: () => <Redirect to="/app/integrated/" />,
      },
    ],
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: SimpleLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/integrated/" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
