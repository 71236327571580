import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  PropTypes,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { toKebabCase } from '../utils/core';
import { MouseEventHandler } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginLeft: -12,
  },
}));

type ProgressButtonProps = {
  variant?: 'text' | 'outlined' | 'contained';
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  onClick?: MouseEventHandler;
  type?: string;
  color?: PropTypes.Color;
  size?: 'small' | 'medium' | 'large';
};

export function ProgressButton(props: ProgressButtonProps) {
  const classes: ClassNameMap = useStyles();

  return (
    <Box
      className={classes.wrapper}
      data-testid={`${toKebabCase(props.label)}-progress-button`}
    >
      <Button
        fullWidth={props.fullWidth}
        size={props.size}
        color={props.color ?? 'secondary'}
        variant={props.variant || 'contained'}
        type={!!props.onClick ? 'button' : 'submit'}
        onClick={props.onClick}
        disabled={props.disabled || props.loading}
        data-testid={`${toKebabCase(props.label)}-button`}
      >
        {props.loading && (
          <>
            <CircularProgress
              data-testid={`${toKebabCase(props.label)}-button-loading`}
              size={24}
              className={classes.buttonProgress}
            />
            <span>{props.label}</span>
          </>
        )}
        {!props.loading && <span>{props.label}</span>}
      </Button>
    </Box>
  );
}
