import { Typography, Button, Box } from '@material-ui/core';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: any;
}) {
  return (
    <Box role="alert">
      <Typography>Something went wrong:</Typography>
      <Box my={3}>
        <pre>{error.message}</pre>
        <pre>{error.stack}</pre>
      </Box>
      <Button
        onClick={resetErrorBoundary}
        style={{ marginRight: '15px' }}
        variant="contained"
      >
        Try Again
      </Button>

      <Button
        onClick={() => window.location.replace('/')}
        variant="contained"
        color="primary"
      >
        Home
      </Button>
    </Box>
  );
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.error(error, info);
};

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <App />
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);
