import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from '../components/SplashScreen';
import axios from '../utils/axios';
import { Tools } from '@babylonjs/core';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken: any) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return (decoded as any).exp > currentTime;
};

const validateUser = async (accessToken: any, idToken: any) => {
  try {
    const response = await axios.post('/api/validate', {
      accessToken,
      idToken,
    });

    const { user, jwtToken } = response.data;

    if (!user) {
      // TODO: remove this approach, try throwing instead
      throw new Error('Invalid access token');
    }

    return {
      user,
      jwtToken,
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const setSession = (accessToken: any) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    Tools.CustomRequestHeaders.Authorization = `Bearer ${accessToken}`;
    Tools.UseCustomRequestHeaders = true;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  validate: (accessToken: string, idToken: string) => Promise.resolve(null),
});

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const validate = async (
    accessToken: string,
    idToken: string,
  ): Promise<string | null> => {
    try {
      const { user, jwtToken: token } = await validateUser(
        accessToken,
        idToken,
      );

      if (token) {
        localStorage.setItem('jwt_token', token);
        localStorage.setItem('user', JSON.stringify(user));
        setSession(token);
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return user.status;
    } catch (error: any) {
      const userStatusFromError = 'ERROR';
      /*
      const userStatusFromError = error?.graphQLErrors?.[0]?.extensions
        ?.status as UserStatusType;
*/
      if (userStatusFromError) {
        return userStatusFromError;
      }
      return null;
    }
  };

  /*
    const validate = async (accessToken: any, idToken: any) => {
      try {
        const { user, jwtToken } = await validateUser(accessToken, idToken);

        setSession(jwtToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      } catch (err) {
        console.error(err);
        logout();
      }
    };
  */

  const login = async (username: any, password: any) => {
    const response = await axios.post('/api/login', {
      username,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const userResponse = await axios.get('/api/users/me');
          const user = userResponse.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        validate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
